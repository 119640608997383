import React, { useRef } from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import './foliohero.css'

const FolioHero = ({ anchor }) => {
  const { media } = useStaticQuery(graphql`
    query {
      media: file(relativePath: { eq: "foliohero-media.png" }) {
        childImageSharp {
          fluid(maxWidth: 867, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const onClick = () => {
    !!anchor && anchor.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <section className="foliohero">
      <div className="container">
        <div className="foliohero-body">
          <p className="foliohero-tip">
            <span className="foliohero-tip__item">200+</span> мобильных приложений
          </p>
          <h1 className="foliohero-title">
            Запускаем продукты: <br /> от идеи до первых <br /> пользователей
          </h1>
          <button className="foliohero-button btn btn--primary btn--outline" onClick={onClick}>
            К кейсам
          </button>
          <div className="foliohero-stats__wrap">
            <ul className="foliohero-stats">
              <li className="foliohero-statunit">
                <p className="foliohero-statunit__title">
                  <span className="title__count">7</span>человек
                </p>
                <p className="foliohero-statunit__text">
                  Средняя
                  <br className="hidden sm:block md:hidden" /> команда проекта
                </p>
              </li>

              <li className="foliohero-statunit">
                <p className="foliohero-statunit__title">
                  <span className="title__count">4</span>месяца
                </p>
                <p className="foliohero-statunit__text">
                  Среднее
                  <br className="hidden sm:block md:hidden" /> время проекта
                </p>
              </li>

              <li className="foliohero-statunit">
                <p className="foliohero-statunit__title">
                  <span className="title__count">3</span>млн
                </p>
                <p className="foliohero-statunit__text">
                  Средняя
                  <br className="hidden sm:block md:hidden" /> стоимость проекта
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="foliohero-media">
          <Img fluid={media.childImageSharp.fluid} />
        </div>
      </div>
    </section>
  )
}

export default FolioHero
