import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { always, compose, evolve, pathOr } from 'ramda'
import FolioWork from '../FolioWork'

import './folioworks.css'

const getImage = compose(always, pathOr(null, ['node', 'childImageSharp', 'fluid']))

const FolioWorks = ({ anchor }) => {
	const { works, images_sm, images_md, images_lg } = useStaticQuery(graphql`
		query {
			works: allPortfolioJson {
				edges {
					node {
						id
						project_name
						title
						description
						cardImage_sm
						cardImage_md
						cardImage_lg
						googleplayurl
						appstoreurl
						backgroundColor
						backgroundSlider
						type
						isWhite
					}
				}
			}
			images_sm: allFile(filter: { relativePath: { regex: "/-card-image-sm/" } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(maxWidth: 640, maxHeight: 800, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
			images_md: allFile(filter: { relativePath: { regex: "/-card-image-md/" } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(maxWidth: 974,maxHeight: 945, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
			images_lg: allFile(filter: { relativePath: { regex: "/-card-image-lg/" } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(maxWidth: 1248, maxHeight: 784, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`)

	return (
		<section className="folioworks" ref={anchor} id="folioworks">
			<div className="container">
				<h2 className="folioworks__title">Кейсы</h2>
			</div>

			<ul className="folioworks__list">
				{works.edges.map(({ node }) => {
					const image_sm = images_sm.edges.find((image) => image.node.relativePath === node.cardImage_sm)
					const image_md = images_md.edges.find((image) => image.node.relativePath === node.cardImage_md)
					const image_lg = images_lg.edges.find((image) => image.node.relativePath === node.cardImage_lg)

					const work = evolve(
						{
							cardImage_sm: getImage(image_sm),
							cardImage_md: getImage(image_md),
							cardImage_lg: getImage(image_lg),
						},
						node
					)

					return <FolioWork key={work.id} {...work}/>
				})}
			</ul>
		</section>
	)
}

export default FolioWorks
