import React, { useRef } from 'react'

import Layout from '../components/Layout'
import FolioHero from '../components/FolioHero'
import FolioWorks from '../components/FolioWorks'

const Portfolio = () => {
  const anchor = useRef(null)

  return (
    <Layout
      title="Примеры и портфолио разработанных приложений | AppCraft"
      content="✅ Портфолио, кейсы и примеры реализованных мобильных приложений студии AppCraft. За 10 лет мы создали более 200 мобильных приложений."
      keywords="разработка мобильных приложений, заказать мобильное приложение, мобильное приложение на заказ, стоимость мобильного приложения, стартап, продвижение"
      ogSiteName="AppCraft"
      ogTitle="Студия Appcraft | Разработка мобильных приложений для Android и iOS"
      ogDescription="Разработка мобильных продуктов от студии, которая создает и развивает собственные проекты. Эффективные решения для клиентов на iOS и Android, основанные на собственном опыте"
    >
      <FolioHero anchor={anchor} />
      <FolioWorks anchor={anchor} />
    </Layout>
  )
}

export default Portfolio
